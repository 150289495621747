import * as React from "react"
import Typography from "@material-ui/core/Typography"
import MuiLink from "@material-ui/core/Link"

const Copyright: React.FC = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Copyright © ${new Date().getFullYear()} `}
      <MuiLink color="inherit" href="https://jorgensauce.com">
        JORGENSAUCE LLC
      </MuiLink>
    </Typography>
  )
}

export default Copyright
