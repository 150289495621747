import * as React from "react"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Copyright from "../components/Copyright"
import "./index.scss"

export default function Index() {
  return (
    <>
      <Container maxWidth="md">
        <Box my={4} style={{ textAlign: "center" }}>
          <img src={"logo.png"} style={{ width: 300 }} />
          <Typography variant="h5" component="h1" align={"center"} gutterBottom>
            <span style={{ color: "#b8b8b8" }}>Future home of something</span>
            &nbsp;
            <span style={{ color: "#a6da48" }}>saucesome!</span>
          </Typography>
        </Box>
      </Container>
      <div
        style={{
          margin: 10,
          position: "fixed",
          bottom: 0,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Copyright />
      </div>
    </>
  )
}
